import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function getAllAssetTypes(authToken: string, queryParams?: any) {
    const endpoint = `${SERVER_BASE_URL}/asset-type`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            query: {
                ...(queryParams ? { queryParams } : {}),
            },
        },
    });
}

function getAssetTypeById(authToken: string, assetTypeId: string) {
    const endpoint = `${SERVER_BASE_URL}/asset-type`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: {
                assetTypeId: assetTypeId,
            },
        },
    });
}

function updateAssetType(
    data: {
        id: string;
        name?: string;
        description?: string;
        category?: string;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/asset-type`;
    return api.update(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function createAssetType(
    data: {
        name: string;
        description?: string;
        category?: string;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/asset-type`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function deleteAssetType(id: string, authToken: string) {
    const endpoint = `${SERVER_BASE_URL}/asset-type`;
    return api.delete(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: [id],
        },
    });
}

export { getAllAssetTypes, updateAssetType, createAssetType, deleteAssetType, getAssetTypeById };
