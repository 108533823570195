import { login, logout, signup, forgotPassword, getRefreshToken } from './auth';
import { getAllAssetTypes, updateAssetType, createAssetType, deleteAssetType, getAssetTypeById } from './assetType';
import { getAllAssets, updateAsset, createAsset, deleteAsset, getAssetById } from './asset';

export {
    //auth
    login,
    logout,
    signup,
    forgotPassword,
    getRefreshToken,
    // asset types
    getAllAssetTypes,
    updateAssetType,
    createAssetType,
    deleteAssetType,
    getAssetTypeById,
    // assets
    getAllAssets,
    updateAsset,
    createAsset,
    deleteAsset,
    getAssetById,
};
