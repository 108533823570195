import jwtDecode from 'jwt-decode';
import axios from 'axios';
import config from '../../config';
import { useUser } from '../../hooks';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        if (error && error.response && error.response.status === 404) {
            // window.location.href = '/not-found';
        } else if (error && error.response && error.response.status === 403) {
            window.location.href = '/access-denied';
        } else {
            switch (error.response.status) {
                case 401:
                    message = 'Invalid credentials';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                case 498:
                    const [loggedInUser] = useUser();
                    if (error?.response?.data?.errorMessage === 'Expired Token') {
                        let url = `${SERVER_BASE_URL}/refreshToken`;
                        const response = await axios.post(url, {
                            token: loggedInUser.refreshToken,
                        });
                        message = {
                            message: 'Expired Token',
                            refreshedSession: response.data,
                        };
                    } else {
                        message = 'Authentication failed.';
                    }

                    break;
                case 500:
                    message = 'Internal Server Error';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'adminto_user';
const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromCookie = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export const getUserFromLocalStorage = () => {
    const user = localStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

class APICore {
    /**
     * Fetches data from given url
     */
    get = (url: string, options?: { params?: { query?: any; path?: any }; headers?: any }) => {
        let queryString = '';
        let pathString = '';
        if (options?.params?.query) {
            queryString = Object.keys(options?.params?.query)
                .map((key) => key + '=' + options?.params?.query[key])
                .join('&');
        }
        if (options?.params?.path) {
            pathString = Object.keys(options?.params?.path)
                .map((key) => {
                    return options?.params?.path;
                })
                .join('/');
        }
        if (options?.headers) {
            return axios
                .get(`${url}/${pathString}?${queryString}`, {
                    headers: options.headers,
                })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        }
    };

    getFile = (url: string, params: any) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls: string, params: any) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url: string, data: any, options?: { params?: { query?: any; path?: any }; headers?: any }) => {
        return axios
            .post(url, data, {
                headers: {
                    ...(options?.headers ? options.headers : {}),
                },
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    /**
     * Updates patch data
     */
    updatePatch = (url: string, data: any) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url: string, data: any, options?: { params?: { query?: any; path?: any }; headers?: any }) => {
        return axios
            .put(url, data, {
                headers: options?.headers,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    /**
     * Deletes data
     */
    delete = (url: string, options?: { params?: { query?: any; path?: any }; headers?: any }) => {
        if (options?.params?.path) {
            for (let param of options.params.path) {
                url = url + `/${param}`;
            }
        }
        return axios.delete(url, {
            headers: {
                ...(options?.headers ? options.headers : {}),
            },
        });
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = (): {
        isAuthenticated: boolean;
        tokenExpired: boolean;
        userSession: any;
        refreshRedux?: boolean;
    } => {
        try {
            let user = this.getLoggedInUser();
            let refreshRedux = false;

            if (!user) {
                user = this.getLoggedInUserFromLocalStorage();
                refreshRedux = true;
            }

            if (!user) {
                return { isAuthenticated: false, tokenExpired: false, refreshRedux, userSession: undefined };
            }
            const decoded: any = jwtDecode(user.accessToken);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                return { isAuthenticated: false, tokenExpired: true, refreshRedux, userSession: user };
            } else {
                return { isAuthenticated: true, tokenExpired: false, refreshRedux, userSession: user };
            }
        } catch (error: any) {
            return { isAuthenticated: false, tokenExpired: false, refreshRedux: false, userSession: undefined };
        }
    };

    setLoggedInUser = (session: any) => {
        if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            sessionStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

    setUserInLocalStorage = (session: any) => {
        if (session) localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            localStorage.removeItem(AUTH_SESSION_KEY);
        }
    };
    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromCookie();
    };

    getLoggedInUserFromLocalStorage = () => {
        return getUserFromLocalStorage();
    };

    setUserInSession = (modifiedUser: any) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { user } = JSON.parse(userInfo);
            this.setLoggedInUser({ ...user, ...modifiedUser });
        }
    };

    getRefreshToken = (token?: string) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        const endpoint = `${SERVER_BASE_URL}/refreshToken`;
        if (token) {
            return this.create(endpoint, {
                token,
            })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        } else {
            if (userInfo) {
                const { accessToken } = JSON.parse(userInfo);
                return this.create(endpoint, {
                    token: accessToken,
                })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            }
        }
    };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
    const { token } = user;
    if (token) {
        setAuthorization(token);
    }
}

export { APICore, setAuthorization };
