import { Navigate, useLocation } from 'react-router-dom';

// helpers
import { APICore, setAuthorization } from '../helpers/api/apiCore';

// hooks
import { useRedux, useUser } from '../hooks';
import React, { useEffect, useState } from 'react';
import { getRefreshToken } from '../helpers';
import { authApiResponseSuccess } from '../redux/actions';
import { AuthActionTypes } from '../redux/auth/constants';
import Loader from '../components/Loader';

type PrivateRouteProps = {
    component: React.ElementType;
    roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const [loggedInUser] = useUser();
    const [isSessionValid, setIsSessionValid] = useState<boolean | null>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { dispatch, appSelector } = useRedux();

    const api = new APICore();

    const renderRouteComponent = () => {
        setIsLoading(false);
        setIsSessionValid(true);
    };

    const renderLoaderComponent = () => {
        setIsSessionValid(false);
        setIsLoading(true);
    };

    const navigateToLogin = () => {
        setIsLoading(false);
        setIsSessionValid(false);
    };

    const checkUserPermission = () => {
        let userRole = loggedInUser.roles.find((elem: { roleId: string; roleType: string }) => {
            return elem.roleType.toLowerCase() === roles?.toLowerCase();
        });

        return roles && userRole === undefined ? false : true;
    };

    useEffect(() => {
        renderLoaderComponent();
        const authCheck = api.isUserAuthenticated();
        if (authCheck.isAuthenticated) {
            if (!checkUserPermission) {
                navigateToLogin();
            }
            if (authCheck.refreshRedux) {
                dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, api.getLoggedInUserFromLocalStorage()));
            }
            renderRouteComponent();
        } else {
            if (authCheck.tokenExpired) {
                getRefreshToken({
                    token: authCheck.userSession?.refreshToken || loggedInUser.refreshToken,
                })
                    .then((response) => {
                        if (response.data) {
                            dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, response.data));

                            api.setLoggedInUser(response.data);
                            setAuthorization(response.data['accessToken']);

                            if (!checkUserPermission()) {
                                navigateToLogin();
                            }
                            renderRouteComponent();
                        } else {
                            navigateToLogin();
                        }
                    })
                    .catch((error) => {
                        navigateToLogin();
                    });
            } else {
                navigateToLogin();
            }
        }
    }, [location]);

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : isSessionValid ? (
                React.createElement(RouteComponent)
            ) : (
                <Navigate to={'/auth/login'} state={{ from: location }} replace />
            )}
        </div>
    );
};

export default PrivateRoute;
